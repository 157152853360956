html {
  overflow: hidden;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: white;
  max-width: 500px;
  padding: 24px;
}

.modal--content {
  line-height: 24px;
}

.modal--content a {
  color: #222222;
}

.modal--close {
  position: absolute;
  top: 8px;
  right: 8px;
  font-weight: bold;
}

.menu {
  position: absolute;
  top: 24px;
  right: 24px;
}

.menu--item {
  display: inline-block;
  font-size: 24px;
  font-weight: bold;
  color: #222222;
  border: none;
  padding: 0 6px;
  margin: 0 6px;
  cursor: pointer;
  background-color: transparent;
}

.menu--item:hover {
  text-decoration: underline;
}


[data-emoji] {
  font-style: normal;
  font-weight: normal;
}

[data-emoji]:before {
  content: attr(data-emoji);
  margin-right: .125em;
}

@media only screen and (max-width: 600px) {
  .modal {
    max-width: 90vw;
  }
}
